import { createBrowserRouter } from "react-router-dom";

//Layouts
import MainLayout from "../layouts/main-layout";
import AdminDashboardLayout from "../layouts/admin-dashboard-layout";
import CustomerDashboardLayout from "../layouts/customer-dashboard-layout";
import ResellerDashboardLayout from "../layouts/reseller-dashboard-layout";
import SubresellerDashboardLayout from "../layouts/subreseller-dashboard-layout";
import AuthLayout from "../layouts/auth-layout";
import Landing from "pages/landing";
import BankAccountsPage from "pages/landing/bank-accounts";
import KullaniciSozlesmesi from "pages/landing/kullanici-sozlesmesi";
import Iletisim from "pages/landing/iletisim";
import KVKK from "pages/landing/kvkk";
import BayilikBasvurusu from "pages/landing/bayilik-basvurusu"

//Admin Pages
import AdminMainPage from "pages/panels/admin/main-page";
import AdminUsersPage from "pages/panels/admin/users-page";
import AdminUserDetailPage from "pages/panels/admin/users-page/user-detail-page";
import AdminUserCreatePage from "pages/panels/admin/users-page/user-create-page";
import AdminResellersPage from "pages/panels/admin/resellers-page";
import AdminResellersDetailPage from "pages/panels/admin/resellers-page/resellers-detail-page";
import AdminSubresellersPage from "pages/panels/admin/subresellers-page";
import AdminSubresellersDetailPage from "pages/panels/admin/subresellers-page/subresellers-detail-page";
import AdminOrdersPage from "pages/panels/admin/orders-page";
import AdminOrderDetailPage from "pages/panels/admin/orders-page/order-detail-page";
import AdminLoginPage from "pages/panels/admin/login-page";
import AdminSettingsPage from "pages/panels/admin/settings-page";
import AdminPaymentsPage from "pages/panels/admin/payments-page";
import AdminCreatePaymentPage from "pages/panels/admin/payments-page/create-payment-page";
import AdminResellersCreatePage from "pages/panels/admin/resellers-page/resellers-create-page";
import AdminSubresellersCreatePage from "pages/panels/admin/subresellers-page/subresellers-create-page";
import AdminPaymentsDetailPage from "pages/panels/admin/payments-page/payments-detail-page";
import AdminDiamondTransfersPage from "pages/panels/admin/diamond-transfers-page";
import AdminVeparaListPage from "pages/panels/admin/vepara-list-page";
import AdminCryptoAddressPage from "pages/panels/admin/crypto-address-page";
import AdminPayoutsPage from "pages/panels/admin/payouts-page";
import AdminTotalSalesPage from "pages/panels/admin/total-sales-page";
import AdminTotalPayoutsPage from "pages/panels/admin/total-payouts-page";
import AdminTotalDiamondPayoutsPage from "pages/panels/admin/total-diamondPayouts-page";
import AdminBankAccountsPage from "pages/panels/admin/bank-accounts-page";
import AdminCreateBankAccountsPage from "pages/panels/admin/bank-accounts-page/create-bank-accounts-page";
import AdminDetailBankAccountsPage from "pages/panels/admin/bank-accounts-page/bank-accounts-detail-page";
import AdminCreateOtpPage from "pages/panels/admin/create-otp-page";

//Customer Pages
import CustomerLoginPage from "pages/panels/customer/login-page";
import CustomerMainPage from "pages/panels/customer/main-page";
import CustomerOrdersPage from "pages/panels/customer/orders-page";
import CustomerOrderDetailPage from "pages/panels/customer/orders-page/order-detail-page";
import CustomerSettingsPage from "pages/panels/customer/settings-page";
import CustomerOrderCreatePage from "pages/panels/customer/orders-page/order-create-page";
import CustomerSatinAlPage from "pages/panels/customer/satin-al-page";

//Reseller Pages
import ResellerLoginPage from "pages/panels/reseller/login-page";
import ResellerMainPage from "pages/panels/reseller/main-page";
import ResellerOrdersPage from "pages/panels/reseller/orders-page";
import ResellerOrderDetailPage from "pages/panels/reseller/orders-page/order-detail-page";
import ResellerOrderCreatePage from "pages/panels/reseller/orders-page/order-create-page";
import ResellerOrderRetailCreatePage from "pages/panels/reseller/orders-page/order-retail-create-page";
import ResellerSubresellersCreatePage from "pages/panels/reseller/subresellers-page/subresellers-create-page";
import ResellerSubresellersDetailPage from "pages/panels/reseller/subresellers-page/subresellers-detail-page";
import ResellerSubresellersPage from "pages/panels/reseller/subresellers-page";
import ResellerProfilePage from "pages/panels/reseller/profile-page";
import ResellerPayoutsPage from "pages/panels/reseller/payouts-page";
import ResellerCreatePayoutsPage from "pages/panels/reseller/payouts-page/create-payouts-page";
import ResellerDiamondTransfersPage from "pages/panels/reseller/diamond-transfers-page";
import ResellerDiamondTransfersCreatePage from "pages/panels/reseller/diamond-transfers-page/create-diamond-transfer-page"

//Subreseller Pages
import SubresellerLoginPage from "pages/panels/subreseller/login-page";
import SubresellerMainPage from "pages/panels/subreseller/main-page";
import SubresellerOrdersPage from "pages/panels/subreseller/orders-page";
import SubresellerOrderDetailPage from "pages/panels/subreseller/orders-page/order-detail-page";
import SubresellerOrderCreatePage from "pages/panels/subreseller/orders-page/order-create-page";
import SubresellerProfilePage from "pages/panels/subreseller/profile-page";
import CustomerRegisterPage from "pages/panels/customer/register-page";


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "banka",
        element: <BankAccountsPage />
      },
      {
        path: "iletisim",
        element: <Iletisim />
      },
      {
        path: "kullanici-sozlesmesi",
        element: <KullaniciSozlesmesi />
      },
      {
        path: "kvkk",
        element: <KVKK />
      },
      {
        path:"bayilik-basvurusu",
        element: <BayilikBasvurusu />
      }
    ],

  },
  {
    path: "/admin",
    element: <AdminDashboardLayout />,
    children: [
      {
        index: true,
        element: <AdminMainPage />,
      },
      {
        path: "customers",
        element: <AdminUsersPage />,

      },
      {
        path: "create-otp",
        element: <AdminCreateOtpPage />,

      },
      {
        path: "customers/detail/:id",
        element: <AdminUserDetailPage />,
      },
      {
        path: "customers/create",
        element: <AdminUserCreatePage />,
      },
      {
        path: "resellers",
        element: <AdminResellersPage />,

      },
      {
        path: "resellers/detail/:id",
        element: <AdminResellersDetailPage />,
      },
      {
        path: "resellers/create",
        element: <AdminResellersCreatePage />,
      },
      {
        path: "subresellers",
        element: <AdminSubresellersPage />,

      },
      {
        path: "subresellers/detail/:id",
        element: <AdminSubresellersDetailPage />,
      },
      {
        path: "subresellers/create",
        element: <AdminSubresellersCreatePage />,
      },

      {
        path: "orders",
        element: <AdminOrdersPage />,
      },
      {
        path: "orders/detail/:id",
        element: <AdminOrderDetailPage />,
      },
      {
        path: "payments",
        element: <AdminPaymentsPage />,
      },
      {
        path: "payments/detail/:id",
        element: <AdminPaymentsDetailPage />,
      },
      {
        path: "payments/create",
        element: <AdminCreatePaymentPage />,
      },

      {
        path: "settings",
        element: <AdminSettingsPage />,
      },
      {
        path: "diamond-transfers",
        element: <AdminDiamondTransfersPage />,
      },
      {
        path: "vepara-list",
        element: <AdminVeparaListPage />,
      },
      {
        path: "cryptoaddress",
        element: <AdminCryptoAddressPage />,
      },
      {
        path: "payouts",
        element: <AdminPayoutsPage />,
      },
      {
        path: "total-sales",
        element: <AdminTotalSalesPage />,
      },
      {
        path: "total-payouts",
        element: <AdminTotalPayoutsPage />,
      },
      {
        path: "total-diamond-payouts",
        element: <AdminTotalDiamondPayoutsPage />,
      },
      {
        path: "bank-accounts",
        element: <AdminBankAccountsPage />,
      },
      {
        path: "bank-accounts/create",
        element: <AdminCreateBankAccountsPage />,
      },
      {
        path: "bank-accounts/detail/:id",
        element: <AdminDetailBankAccountsPage />,
      },
    ],
  },
  {
    path: "/customer",
    element: <CustomerDashboardLayout />,
    children: [
      {
        index: true,
        element: <CustomerMainPage />,
      },
      {
        path: "orders",
        element: <CustomerOrdersPage />,
      },
      {
        path: "orders/detail/:id",
        element: <CustomerOrderDetailPage />,
      },
      {
        path: "orders/create",
        element: <CustomerSatinAlPage />,
      },
      {
        path: "profile",
        element: <CustomerSettingsPage />,
      },
      {
        path: "satin-al",
        element: <CustomerSatinAlPage />,
      }
    ],
  },
  {
    path: "/reseller",
    element: <ResellerDashboardLayout />,
    children: [
      {
        index: true,
        element: <ResellerMainPage />,
      },
      {
        path: "diamond-transfers",
        element: <ResellerDiamondTransfersPage />,
      },
      {
        path: "diamond-transfers/create",
        element: <ResellerDiamondTransfersCreatePage />,
      },
      {
        path: "profile",
        element: <ResellerProfilePage />,
      },
      {
        path: "orders",
        element: <ResellerOrdersPage />,
      },
      {
        path: "payouts",
        element: <ResellerPayoutsPage />,
      },
      {
        path: "payouts/create",
        element: <ResellerCreatePayoutsPage />,
      },
      {
        path: "subresellers",
        element: <ResellerSubresellersPage />,
      },
      {
        path: "subresellers/create",
        element: <ResellerSubresellersCreatePage />,
      },
      {
        path: "subresellers/detail/:id",
        element: <ResellerSubresellersDetailPage />,
      },
      {
        path: "orders/detail/:id",
        element: <ResellerOrderDetailPage />,
      },
      {
        path: "orders/create",
        element: <ResellerOrderCreatePage />,
      },
      {
        path: "orders/retail/create",
        element: <ResellerOrderRetailCreatePage />,
      },
    ],
  },
  {
    path: "/subreseller",
    element: <SubresellerDashboardLayout />,
    children: [
      {
        index: true,
        element: <SubresellerMainPage />,
      },
      {
        path: "profile",
        element: <SubresellerProfilePage />,
      },
      {
        path: "orders",
        element: <SubresellerOrdersPage />,
      },
      {
        path: "orders/detail/:id",
        element: <SubresellerOrderDetailPage />,
      },
      {
        path: "orders/create",
        element: <SubresellerOrderCreatePage />,
      }
    ],
  },
  {
    path: "/register",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <CustomerRegisterPage />,
      },

    ]
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      {
        path: "admin",
        element: <AdminLoginPage />,
      },
      {
        path: "customer",
        element: <CustomerLoginPage />
      },
      {
        path: "reseller",
        element: <ResellerLoginPage />
      },
      {
        path: "subreseller",
        element: <SubresellerLoginPage />
      }
    ]
  }
]);

export { router };
