import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalSalesDetail } from "redux/admin/stats/statsSlice";
import { getResellers, getSubresellers } from "redux/admin/users/usersSlice";
import Top5 from "components/Top5";

const BarChart = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("reseller");
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().slice(0, 10);
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10);
  });
  const { totalSalesDetail } = useSelector((state) => state.adminStats);
  const { resellers, subresellers } = useSelector((state) => state.adminUsers);


  useEffect(() => {
    dispatch(getResellers());
    dispatch(getSubresellers());
  }, [dispatch]);


  useEffect(() => {
    dispatch(
      getTotalSalesDetail({
        start: `${startDate}T00:30:20.969133+00:00`,
        end: `${endDate}T00:30:20.969133+00:00`,
        per_user: true,
      })
    );
  }, [dispatch, startDate, endDate]);


  const sortedData = useMemo(() => {
    if (!totalSalesDetail) return [];

    let filteredData = totalSalesDetail;
    if (userType === "all") {
      filteredData = filteredData.filter(
        (d) => d.user_type === "reseller" || d.user_type === "subreseller"
      );
    } else {
      filteredData = filteredData.filter((d) => d.user_type === userType);
    }

    return filteredData.map((dataItem) => {
      let newName = "";
      if (dataItem.user_type === "reseller") {
        const matchingReseller = resellers?.data?.find(
          (reseller) => reseller.id === dataItem.user_id
        );
        if (matchingReseller) {
          newName = matchingReseller.name;
        }
      } else if (dataItem.user_type === "subreseller") {
        const matchingSubreseller = subresellers?.data?.find(
          (subreseller) => subreseller.id === dataItem.user_id
        );
        if (matchingSubreseller) {
          newName = matchingSubreseller.name;
        }
      }
      return {
        ...dataItem,
        user_name: newName,
      };
    });
  }, [totalSalesDetail, userType, resellers, subresellers]);

  return (
    <>
      <div className="w-full md:col-span-2 relative lg:h-[70vh] h-[50vh] m-auto border-gray-600 rounded-lg bg-gray-900 flex flex-col justify-between">
        <h2 className="text-2xl text-center text-white-100 font-semibold mt-4">
          En İyi 5 Bayi
        </h2>
        <div>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
          <span className="text-white-100 text-xl">-</span>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
        </div>
        <Top5 data={sortedData} reseller={resellers} subreseller={subresellers} />
      </div>
    </>
  );
};

export default BarChart;
