import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import veparaListService from './veparaListService.js';


export const getTransfers = createAsyncThunk('admin/vepara-tx', async (params, { rejectWithValue }) => {
  try {
    const response = await veparaListService.get(params);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return rejectWithValue(message);
  }
});




const initialState = {
  transferList: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
};

export const veparaListSlice = createSlice({
  name: 'adminVeparaList',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransfers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transferList = action.payload;
      })
      .addCase(getTransfers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  }
})

export const { reset } = veparaListSlice.actions;
export default veparaListSlice.reducer;
