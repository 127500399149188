import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import payoutService from './payoutService';


export const getPayouts = createAsyncThunk('admin/payouts', async (params, {rejectWithValue}) => {
    try {
        const response = await payoutService.payouts(params);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const finalizePayouts = createAsyncThunk('admin/payouts/finalize', async (data, {rejectWithValue}) => {
    try {
        const response = await payoutService.finalize(data);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return rejectWithValue(message);
    }
});



const initialState = {
    payouts: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    isFinalizePayoutSuccess: false,
    isFinalizePayoutLoading: false,
    isFinalizePayoutError: false,
    message:''
};

export const payoutsSlice = createSlice({
    name: 'adminPayouts',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
            state.isFinalizePayoutError = false;
            state.isFinalizePayoutLoading = false;
            state.isFinalizePayoutSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPayouts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPayouts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.payouts = action.payload;
            })
            .addCase(getPayouts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
        builder
            .addCase(finalizePayouts.pending, (state) => {
                state.isFinalizePayoutLoading = true;
            })
            .addCase(finalizePayouts.fulfilled, (state, action) => {
                state.isFinalizePayoutLoading = false;
                state.isFinalizePayoutSuccess = true;
              
            })
            .addCase(finalizePayouts.rejected, (state, action) => {
                state.isFinalizePayoutLoading = false;
                state.isFinalizePayoutError = true;
                state.isFinalizePayoutSuccess = false;
                state.message = action.payload;
             
            });
  
    }
})

export const {reset} = payoutsSlice.actions;
export default payoutsSlice.reducer;