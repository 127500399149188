import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useCustomSettings } from "hooks/useCustomSettings";
import { settings } from "redux/reseller/settings/settingsSlice";

const keyTextMapping = {
  usdtry_exchange_rate: "1 Dolar",
};

const Header = () => {
  const { profile } = useSelector((state) => state.resellerProfile);
  const { data } = useSelector((state) => state.resellerSettings);
  const exchangeRate = useCustomSettings(
    data,
    keyTextMapping,
    "usdtry_exchange_rate"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settings());
  }, [dispatch]);

  return (
    <header>
      <div class="bg-secondary bg-opacity-60 shadow-xl px-4 py-4 text-white-100">
        <p class="text-center text-lg font-bold">
          Güncel Dolar Kuru: {exchangeRate.value}₺
        </p>
      </div>
      <div className="mx-auto px-4 sm:px-6 sm:py-8 lg:px-8">
        <div className="sm:flex sm:items-center mt-2 md:mt-0 sm:justify-between">
          <div className="text-center sm:text-left">
            <h1 className="text-2xl font-bold text-white-100 sm:text-3xl">
              Hoşgeldin, {profile?.name}
            </h1>
          </div>

          <div className="mt-4 mb-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
            <Link
              className="md:hidden block rounded-lg bg-primary px-5 py-3 text-xl font-medium text-white-100 transition hover:bg-indigo-700 focus:outline-none focus:ring"
              to="/reseller/orders"
            >
              Dekontları Gör
            </Link>
            <Link
              className="block rounded-lg bg-secondary px-5 py-3 text-xl font-medium text-white-100 transition hover:bg-indigo-700 focus:outline-none focus:ring"
              to="/reseller/orders/create"
            >
              Dekont Gir
            </Link>
            <Link
              className="block rounded-lg bg-primary px-5 py-3 text-xl font-medium text-white-100 transition focus:outline-none focus:ring"
              to="/reseller/orders/retail/create?platform=mico"
            >
             ID ile Elmas Yükle Mico
            </Link>
            <Link
              className="block rounded-lg bg-red-400 px-5 py-3 text-xl font-medium text-white-100 transition focus:outline-none focus:ring"
              to="/reseller/orders/retail/create?platform=tango"
            >
             ID ile Elmas Yükle Tango
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
