import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReseller,
  reset,
  detailReseller,
} from "redux/admin/users/usersSlice";
import { BiLoaderCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/tr";

moment.locale("tr");

const ResellersDetail = ({ data }) => {
  const dispatch = useDispatch();

  const [diamondPrice, setDiamondPrice] = useState("");
  const [balance_try, setBalanceTry] = useState("");
  const [commission_rate, setCommisionRate] = useState("");
  const [allowance_try, setAllowanceTry] = useState("");
  const [password, setPassword] = useState("");
  const [paymentKey, setPaymentKey] = useState(null);
  const [payoutFee, setPayoutFee] = useState("");
  const [phone,setPhone] = useState("");
  const [name,setName] = useState("");
  const [active, setActive] = useState(data.is_active);
  const [retailComissionRate,setRetailComissionRate] = useState();
  const [retailComissionRateTango,setRetailComissionRateTango] = useState();

  const {
    isSuccessUpdateReseller,
    isLoadingUpdateReseller,
    isErrorUpdateReseller,
  } = useSelector((state) => state.adminUsers);

  useEffect(() => {
    setDiamondPrice(data?.calculator_diamond_price || 0);
    setBalanceTry(data?.balance_try || 0);
    setCommisionRate(data?.commission_rate || 0);
    setAllowanceTry(data?.allowance_try || 0);
    setActive(data?.is_active || false);
    setPassword(data?.has_password ? "******": "yok");
    setPaymentKey(data?.payment_key || null)
    setPayoutFee(data?.payout_fee || 0)
    setPhone(data?.phone || "")
    setName(data?.name || "")
    setRetailComissionRate(data?.retail_commission_rate || "")
    setRetailComissionRateTango(data?.retail_commission_rate_tango || "")
  }, [data]);

  useEffect(() => {
    if (isSuccessUpdateReseller) {
      toast.success("Bayi Güncellendi");
      dispatch(detailReseller({ search: data.id }));
    }
    if (isErrorUpdateReseller) {
      toast.error("Bayi Güncellenirken Hata Oluştu");
    }
    return () => dispatch(reset());
  }, [isSuccessUpdateReseller, isErrorUpdateReseller, dispatch]);

  const handleChangeActive = (e) => {
    setActive(!active);
    dispatch(
      updateReseller({
        reseller_id: data.id,
        is_active: !active,
      })
    )
      .then((response) => {
        console.log("Güncelleme başarılı:", response);
      })
      .catch((error) => {
        setActive(active); // rollback
        toast.error("Kullanıcı güncellenirken bir hata oluştu.");
      });
  };

  const handleChangePrice = () => {
    if (diamondPrice === "") {
      toast.error("Elmas Satış Fiyatı Boş Bırakılamaz");
      return;
    }
    if (balance_try === "") {
      toast.error("Bakiye Boş Bırakılamaz");
      return;
    }
    if (commission_rate === "") {
      toast.error("Komisyon Oranı Boş Bırakılamaz");
      return;
    }
    if (allowance_try === "") {
      toast.error("Borç (-) Limiti Boş Bırakılamaz");
      return;
    }
    if (paymentKey === null) {
      toast.error("Payment Key Boş Bırakılamaz");
      return;
    }
    if (payoutFee === null) {
      toast.error("Para Çekme Komisyonu Boş Bırakılamaz");
      return;
    }

    if (password != "yok" && password.length < 5) {
      toast.error("Şifre en az 6 karakter olmalıdır (şifreyi kaldırmak için şifreyi \"yok\" yapın)");
      return;
    }

    if (retailComissionRate === null || retailComissionRate === "") {
      toast.error("TID ile Elmas Yükleme Komisyonu Boş Bırakılamaz");
      return;
    }

    if (retailComissionRateTango === null || retailComissionRateTango === "") {
      toast.error("TID ile Elmas Yükleme Komisyonu Boş Bırakılamaz");
      return;
    }

    if (balance_try == data.balance_try) {
      dispatch(
        updateReseller({
          reseller_id: data.id,
          calculator_diamond_price: diamondPrice,
          balance_try: balance_try,
          commission_rate: commission_rate,
          allowance_try: allowance_try,
          payment_key: paymentKey,
          payout_fee: payoutFee,
          retail_commission_rate:retailComissionRate,
          retail_commission_rate_tango:retailComissionRateTango,
          password: (password == "******" ? null : (password == "yok" ? "-" : password))
        })
      );
    } else {
      dispatch(
        updateReseller({
          reseller_id: data.id,
          calculator_diamond_price: diamondPrice,
          balance_try_old: data.balance_try,
          balance_try_new: balance_try,
          commission_rate: commission_rate,
          allowance_try: allowance_try,
          payment_key: paymentKey,
          payout_fee: payoutFee,
          retail_commission_rate:retailComissionRate,
          retail_commission_rate_tango:retailComissionRateTango,
          password: (password == "******" ? null : (password == "yok" ? "-" : password))
        })
      );
    }
  };

  const handleChangeInfo = () => {
    if (phone === "") {
      toast.error("Telefon Numarası Boş Bırakılamaz");
      return;
    }
    if (name === "") {
      toast.error("Bayi Adı Boş Bırakılamaz");
      return;
    }
    

    dispatch(
      updateReseller({
        reseller_id: data.id,
        phone: phone,
        name: name,
       })
    );
  };

  return (
    <div className="bg-white shadow-md min-h-screen rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Kullanıcı Detayı #{data?.id}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Bayi Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Aktiflik Durumu:</span>{" "}
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={active}
                onChange={handleChangeActive}
              />
              <div className="w-11 h-6 bg-white-100 peer-focus:ring-4 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
            </label>
          </p>
          <p className="">
            <span className="font-semibold text-white-100">Adı:</span> {""}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">Telefon Numarası:</span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Alt Bayi Sayısı:</span>{" "}
            {data?.total_subresellers}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Kayıt Tarihi:</span>{" "}
            {moment(data?.created_at).format("Do MMMM YYYY, H:mm:ss")}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Son Güncelleme Tarihi:</span>{" "}
            {moment(data?.updated_at).format("Do MMMM YYYY, h:mm:ss")}
          </p>
          <button
            onClick={handleChangeInfo}
            disabled={isLoadingUpdateReseller}
            className="bg-secondary rounded-md px-4 py-1 text-sm text-white-100 font-medium inline-block"
          >
            {isLoadingUpdateReseller ? (
              <BiLoaderCircle className="animate-spin w-full text-center" />
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
        <div className="bg-gray-900 p-4 rounded space-y-2 shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Finansal Bilgiler
          </h3>
          <p className="">
            <span className="font-semibold text-white-100">Komisyon (%):</span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={commission_rate}
              onChange={(e) => setCommisionRate(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">Bakiye (₺):</span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={balance_try}
              onChange={(e) => setBalanceTry(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              Borç (-) Limiti:
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={allowance_try}
              onChange={(e) => setAllowanceTry(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              Elmas Satış Fiyatı:
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={diamondPrice}
              onChange={(e) => setDiamondPrice(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              Payment Key:
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={paymentKey}
              onChange={(e) => setPaymentKey(e.target.value.toUpperCase())}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              Para Çekme Komisyonu (%):
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={payoutFee}
              onChange={(e) => setPayoutFee(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              ID ile Elmas Yükleme Komisyonu Mico (%):
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={retailComissionRate}
              onChange={(e) => setRetailComissionRate(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              ID ile Elmas Yükleme Komisyonu Tango (%):
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={retailComissionRateTango}
              onChange={(e) => setRetailComissionRateTango(e.target.value)}
            />
          </p>
          <p className="">
            <span className="font-semibold text-white-100">
              Şifre:
            </span>{" "}
            <input
              className="rounded-md pl-2 focus:outline-none"
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </p>
          <button
            onClick={handleChangePrice}
            disabled={isLoadingUpdateReseller}
            className="bg-secondary rounded-md px-4 py-1 text-sm text-white-100 font-medium inline-block"
          >
            {isLoadingUpdateReseller ? (
              <BiLoaderCircle className="animate-spin w-full text-center" />
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResellersDetail;
