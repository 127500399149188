import instance from "api/instance";

const get = async (params) => {
  const response = await instance.get("/admin/vepara-tx", {
    params: params
  });
  if (response.status === 200) {
    return response.data;
  }

  return Promise.reject(response.data);
};

const veparaListService = {
  get
};

export default veparaListService;
