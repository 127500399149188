import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTransfers } from "redux/admin/vepara/veparaListSlice";
import Pagination from "components/Pagination";
import Button from "components/Button";

const VeparaListContainer = () => {
  const dispatch = useDispatch();
  const iref = useRef();
  const data  = useSelector((state) => state.adminVeparaList.transferList);
  const { total, pages, page, per_page } = useSelector(
    (state) => state.adminVeparaList.transferList
  );
  useEffect(() => {
    dispatch(getTransfers({ page: 1, per_page: 15 }));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (!iref?.current || !data) return;
      iref.current.contentWindow.postMessage(data, "*");
    }, 500);
  }, [data]);

  const handlePageChange = (newPage) => {
    dispatch(getTransfers({ page: newPage, per_page: 15 }));
  };

  return (
    <div className="bg-black min-h-screen">
      <div className="p-4 flex justify-between">
      <iframe ref={iref} src="https://skypay.com.tr/vepara_list.html" width="100%" height="1000px" title="Vepara İşlemler"></iframe>

      </div>
      <Pagination
        currentPage={page}
        totalPages={pages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default VeparaListContainer;
