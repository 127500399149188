import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";
import Logo from "components/Logo";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import { RxDashboard, RxPerson } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi"
import { BsShop } from "react-icons/bs"
import { FiSettings } from "react-icons/fi";
import { MdOutlinePayments } from "react-icons/md";
import { logout } from "redux/admin/auth/authSlice";
import { toast } from "react-toastify";
import { IoCashOutline, IoDiamondOutline } from "react-icons/io5";
import { BsBank } from "react-icons/bs"
import { GiOpenChest } from "react-icons/gi";
import {FiKey} from "react-icons/fi";
import { useNavigate } from "react-router-dom";


const sidebarVariants = {
  expanded: {
    width: 240,
  },
  collapsed: {
    width: 80,
  },
};

const textVariants = {
  expanded: {
    opacity: 1,
  },
  collapsed: {
    opacity: 0,
  },
};

const Sidebar = ({ toggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuccessLogout, message } = useSelector((state) => state.adminAuth);

  useEffect(() => {
    if (isSuccessLogout) {
      setTimeout(() => {
        navigate("/login/admin");
      }, 1000);
      toast.success(message);
    }
  }, [isSuccessLogout]);



  const handleLogout = () => {
    dispatch(logout());

  };

  const [isExpanded, setIsExpanded] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (toggle) {
      controls.start("expanded");
      setIsExpanded(true);
    } else {
      controls.start("collapsed");
      setIsExpanded(false);
    }
  }, [toggle]);


  const handleMouseEnter = () => {
    if (window.innerWidth > 768) { // assuming 768px as breakpoint for mobile devices
      controls.start("expanded");
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) { // assuming 768px as breakpoint for mobile devices
      controls.start("collapsed");
      setIsExpanded(false);
    }
  };

  return (
    <motion.div
      animate={controls}
      initial="collapsed"
      variants={sidebarVariants}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`block md:fixed ${toggle !== true ? 'hidden md:block' : ''}  w-20 h-screen p-4 bg-black border-r-[1px] border-gray-700 flex flex-col justify-between z-[999999999]`}
    >
      <div className="flex flex-col items-center">
        <Logo href="/" isMobile={true} />
        <span className="border-b-[1px] border-gray-700 w-full p-2"></span>
        <Link to="/admin">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <RxDashboard size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Anasayfa</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/total-sales">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <GiOpenChest size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Istatistikler</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/create-otp">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
          <FiKey size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">OTP Oluştur</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/customers">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <RxPerson size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Müşteriler</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/bank-accounts">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <BsBank size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Banka Hesapları</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/resellers">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <BsShop size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Bayi Listesi</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/subresellers">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <BsShop size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Alt Bayi Listesi</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/payments">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <MdOutlinePayments size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Ödemeler</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/payouts">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <IoCashOutline size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Para Çekme Talepleri</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/diamond-transfers">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <IoDiamondOutline size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Elmas Transfer Talepleri</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/orders">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <HiOutlineShoppingBag size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Siparişler</span>
            ) : (
              ""
            )}
          </div>
        </Link>
        <Link to="/admin/vepara-list">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <IoDiamondOutline size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Vepara İşlemler</span>
            ) : (
              ""
            )}
          </div>
        </Link>

        <Link to="/admin/cryptoaddress">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <BsShop size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Kripto Adresler</span>
            ) : (
              ""
            )}
          </div>
        </Link>

        <Link to="/admin/settings">
          <div className="flex items-center gap-4 justify-center my-1 py-3 text-white-100 hover:text-secondary transition-all">
            <FiSettings size={20} />
            {isExpanded === true ? (
              <span className=" font-semibold text-base">Ayarlar</span>
            ) : (
              ""
            )}
          </div>
        </Link>
      </div>
      <div>
        <div className="cursor-pointer" onClick={handleLogout}>
          <div className="flex items-center gap-4 justify-center text-white-100 hover:text-secondary transition-all">
            <BiExit size={20} />
            {isExpanded === true ? (
              <span className="font-semibold text-base">Çıkış Yap</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
