import instance from "api/instance";

const payouts = async (params) => {
	const response = await instance.get("/reseller/payout-requests/list",{
		params:params
	});
	if (response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const createPayout = async (data) => {
	const response = await instance.post("/reseller/payout-requests/new",data);

	if (response.status === 201 || response.status === 200) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};

const payoutService = {
	payouts,
    createPayout,
};

export default payoutService;