import React from 'react'
import VeparaListContainer from 'containers/panel/admin/vepara-list-container'

const VeparaListPage = () => {
  return (
    <VeparaListContainer />
  )
}

export default VeparaListPage
